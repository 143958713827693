<template>
  <div id="verifyAccount">
    <div class="container bg-body">
      <div class="row">
        <div class="col-lg-5 col-md-5 col-12">
          <div class="login-logo mt-5">
            <a href="https://cvscreen.com.au/" target="blank"
              ><img
                src="../../assets/img/logo.png"
                class=""
                alt="CV Screen"
                style="height: 100px"
            /></a>
          </div>

          <img
            src="../../assets/img/sgi-registrer.png"
            class="img-fluid img-bottom"
            alt="CV Screen"
          />
        </div>

        <div class="col-lg-7 col-md-7 col-12">
          <div class="login-page bg-body">
            <div class="card bg-white mt100 text-center">
              <div class="card-body text-center pt2000 pb2000">
                <div class="text-center">
                  <b-spinner
                    variant="primary"
                    label="Spinning"
                    v-if="isLoading"
                  ></b-spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as authService from "../../services/auth.service";
import * as messageService from "../../services/message.service";
export default {
  data() {
    return {
      code: this.$route.params.code,
      isLoading: false,
    };
  },
  methods: {
    fnVerifyAccount: async function () {
      try {
        // Progress bar
        this.$Progress.start();
        this.isLoading = true;
        const response = await authService.accountVerification(this.code);

        this.errors = {};

        if (response.data.status_code === 200) {
          this.$router.push({
            name: "resetPassword",
            params: { token: this.code },
          });
        }

        // Progress bar
        this.$Progress.finish();
        this.isLoading = false;
      } catch (error) {
        switch (error.response.status) {
          case 401:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Account verification",
              error.response.data.message
            );
            break;
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Account verification",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Account verification",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Account verification",
              "Something wrong, please try again!"
            );
            break;
        }

        // Progress fail (error)
        this.$Progress.fail();
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.fnVerifyAccount();
  },
};
</script>
